<template>
  <div class="project">
    <Progress size="mini" :active-index="1" :step-list="stepList" />
    <div class="search">
      <img src="@/assets/image/icon-ss.png" />
      <a-input
        v-model="query.projectName"
        placeholder="输入项目或标段名称搜索"
      />
      <a-input v-model="query.sectionCode" placeholder="输入标段编号搜索" />
      <a-select
        style="width:200px"
        placeholder="请选择交易中心"
        v-model="query.channelCode"
      >
        <a-select-option
          v-for="item in statusList"
          :key="item.label"
          :value="item.value"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>

      <a-button type="primary" @click="fetch(1)">
        查询
      </a-button>
      <a-button type="primary" @click="reset">
        重置
      </a-button>
    </div>
    <!-- 表格 -->
    <a-spin :spinning="loading">
      <div class="table">
        <a-table
          :columns="columns"
          :pagination="pagination"
          :loading="loading"
          :data-source="data"
          :scroll="{ y: 500 }"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          @change="pageChange"
        >
          <span slot="marginAmount" slot-scope="space" class="red">
            {{ throundsHandle(space) }}
          </span>
           <a slot="channelCode" slot-scope="space">{{ insureStatus(space) }}</a>
          <span slot="action" slot-scope="space, text">
            <a-button
              v-if="text.applyState === '1'"
              type="primary"
              @click="apply(text)"
            >
              申请
            </a-button>
            <a-button v-else type="primary" disabled>
              已申请
            </a-button>
          </span>
        </a-table>
      </div>
    </a-spin>
  </div>
</template>

<script>
import Progress from "@/components/Progress";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { insureApply, sectionList } from "@/api/insure/project.js";
import { dateFormat } from "@/utils/format.js";
import { info } from "@/api/user/user.js";
import { dictDetail } from "@/api/performance.js";
export default {
  name: "ProView",
  components: {
    Progress
  },
  data() {
    return {
      activeIndex: 0,
      dateFormat,
      back: "<",
      locale,
      loading: false,
      abnormal: "", // 标段异常
      headers: {
        authorization: "authorization-text"
      }, // 请求头
      visible: false, // 弹窗显示
      confirmLoading: false, // 确定加载
      insureProject: {},
      query: { projectStatus: "4" },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      data: [],
      statusList: [],
      columns: [
        {
          // title: "交易中心",
          // dataIndex: "channelCode",
          // key: "channelCode",
          // slots: { title: "交易中心" },
          // scopedSlots: { customRender: "channelCode" }
          title: "交易中心",
          dataIndex: "channelCode",
          key: "channelCode",
          slots: { title: "交易中心" },
          scopedSlots: { customRender: "channelCode" }
        },
        {
          title: "项目名称",
          dataIndex: "projectName",
          key: "projectName",
          slots: { title: "项目名称" },
          scopedSlots: { customRender: "projectName" }
        },
        {
          title: "项目编号",
          dataIndex: "projectNum",
          key: "projectNum"
        },
        {
          title: "标段名称",
          dataIndex: "name",
          key: "name"
        },
        {
          title: "标段编号",
          dataIndex: "sectionCode",
          key: "sectionCode"
        },
        {
          title: "开标时间",
          dataIndex: "marginDeadLine",
          key: "marginDeadLine"
        },
        {
          title: "公告时间",
          dataIndex: "bulletinReleaseTime",
          key: "bulletinReleaseTime"
        },
        {
          title: "保证金金额",
          dataIndex: "marginAmount",
          key: "marginAmount",
          slots: { title: "保证金金额" },
          scopedSlots: { customRender: "marginAmount" }
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" }
        }
      ],
      options: [
        { label: "标段延期", value: "0" },
        { label: "标段流标", value: "1" },
        { label: "标段终止", value: "2" }
      ],
      stepList: [
        { name: "选择项目", flag: 0 },
        { name: "确认项目", flag: 1 },
        { name: "确认投保", flag: 2 },
        { name: "投保审核", flag: 3 },
        { name: "保费支付", flag: 4 },
        { name: "保函发放", flag: 5 }
      ],
      projectStatus: [
        { label: "正常", value: "0" },
        { label: "暂停", value: "1" },
        { label: "终止", value: "2" },
        { label: "流标", value: "3" }
      ]
    };
  },
  mounted() {
    this.insureProject = JSON.parse(sessionStorage.getItem("insureProject"));
    this.fetch(1);
    if (this.$route.query.count) {
      this.activeIndex = Number(this.$route.query.count);
    }
    dictDetail({ dictName: "channel_code" }).then(res => {
      this.statusList = res.data.data;
    });
  },
  methods: {
    insureStatus(status) {
      if (this.statusList) {
        for (const i in this.statusList) {
          if (this.statusList[i].value === status) {
            return this.statusList[i].label;
          }
        }
      } else {
        return "";
      }
    },
    throundsHandle(value) {
      if (value) {
        let a = value.toString().split(".")[0];
        let b = value.toString().split(".")[1];
        return a.replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + (b ? "." + b : "");
      } else {
        return value;
      }
    },
    status(data) {
      const statu = this.projectStatus.filter(item => {
        return item.value === data;
      });
    },
    apply(item) {
      insureApply({
        projectNum: item.projectNum,
        sectionId: item.id
      }).then(res => {
        if (res.data.status === 200) {
          if (res.data.data.certificationFlag === "0") {
            // 未认证
            const that = this;
            this.$confirm({
              title: "提示信息",
              content: "为了投保顺利，请先完成企业认证！",
              okText: "去完善",
              cancelText: "暂不完善",
              onOk() {
                that.$router.push({ name: "entCertForm" });
              },
              onCancel() {}
            });
          } else if (res.data.data.certificationFlag === "2") {
            // 认证中
            const h = this.$createElement;
            this.$info({
              title: "提示信息",
              content: h("div", {}, [
                h("p", "企业认证信息正在加速审批中，请耐心等待！")
              ]),
              okText: "确定",
              onOk() {}
            });
          } else if (res.data.data.certificationFlag === "3") {
            // 认证失败
            const that = this;
            this.$confirm({
              title: "提示信息",
              content:
                "对不起，您的企业认证信息审核未通过，不能发起申请，请修改企业认证信息。",
              okText: "去修改",
              cancelText: "暂不修改",
              onOk() {
                that.$router.push({ name: "entCertForm" });
              },
              onCancel() {}
            });
          } else if (res.data.data.certificationFlag === "4") {
            const that = this;
            this.$confirm({
              title: "提示信息",
              content: "请上传附件。",
              okText: "去上传",
              cancelText: "暂不上传",
              onOk() {
                that.$router.push({ name: "entCertForm" });
              },
              onCancel() {}
            });
          } else {
            info().then(r => {
              if (r.data.data.accCustomerFlag) {
                this.$router.push({
                  path: "/insure",
                  query: { orderNo: res.data.data.orderNo }
                });
                window.localStorage.setItem("visible", true);
              } else {
                this.$router.push({ path: "/user" });
              }
            });
          }
        }
      });
    },
    reset() {
      this.query.projectName = null;
      this.query.sectionCode = null;
      this.query.channelCode = null;

      this.query.projectStatus = "4";
      this.fetch(1);
    },
    fetch(page) {
      this.loading = true;
      sectionList({
        projectName: this.query.projectName,
        sectionCode: this.query.sectionCode,
        channelCode: this.query.channelCode,
        size: 10,
        page
      }).then(res => {
        this.loading = false;
        this.data = res.data.data.content;
        this.pagination.total = res.data.data.totalElements;
        this.pagination.current = page;
      });
    },
    pageChange(e) {
      this.fetch(e.current);
    }
  }
};
</script>
<style lang="less" scoped>
.red {
  color: crimson;
  font-size: 18px;
  font-weight: 500;
}

.project {
  min-height: 666px;

  .titleBar {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    .title {
      span {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
      }

      small {
        font-size: 16px;
        font-family: Microsoft YaHei;
      }
    }

    button {
      height: 30px;
      background: #ffffff;
      border: 1px solid #e1e8ef;
      border-radius: 3px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #aaaaaa;
    }
  }

  .search {
    display: flex;
    margin: 30px 0 20px 0;
    position: relative;

    img {
      width: 14px;
      height: 14px;
      position: absolute;
      top: 9px;
      left: 10px;
      z-index: 99;
    }

    /deep/ .ant-input-affix-wrapper {
      width: 300px;
    }

    /deep/ .ant-input {
      width: 200px;
      height: 32px;
      background: #ffffff;
      border: 1px solid #e1e8ef;
      border-radius: 3px;
      padding-left: 40px;
      margin-right: 10px;
    }

    button {
      background-color: #007ce2;
      margin-left: 30px;
    }
  }
}

.table {
  /deep/ .ant-table-thead > tr > th {
    background-color: #f7faff;
    font-size: 15px;
    color: #333;
    font-weight: bold;
  }

  /deep/ .ant-table-tbody > tr > td {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    min-width: 45px;
    word-break: break-word; /* 文本行的任意字内断开 */
    word-wrap: break-word; /* IE */
  }

  /deep/ .ant-table-placeholder {
    min-height: 500px;
    .ant-empty-normal {
      margin-top: 220px;
    }
  }

  /deep/
    .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background-color: #f7faff;
  }
}
</style>
